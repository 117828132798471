String.prototype.replaceAllOccurrences = function(search, replaceWith) {
    if(search === replaceWith)
        return this;

    let current = this;
    let i = 0;
    while(current.indexOf(search) >= 0 && i < current.length) {
        if(typeof replaceWith === 'function') {
            current = current.replace(search, replaceWith());
        } else {
            current = current.replace(search, replaceWith);
        }
        i++;
    }
    return current;
}

HTMLElement.prototype.setChecked = function(checked) {
    const event = new Event('change');
    this.checked = checked;
    this.dispatchEvent(event);
}
