import {onLoad} from "../onLoad";

onLoad(() => {
   const modalSwitches = document.querySelectorAll('[data-toggle-modal]');
   modalSwitches.forEach(modalSwitch => {
      modalSwitch.addEventListener('click', function() {
          const target = modalSwitch.getAttribute('data-modal-target');
          toggleModal(target);
          checkBody()
      }, false);
   });

   checkBody();

   function toggleModal(target) {
       const modal = document.querySelector(`[data-modal="${target}"]`);

       if(modal) {
           modal.classList.toggle('hidden');

           modal.querySelectorAll('[data-src]').forEach(img => {
               img.src = img.getAttribute('data-src');
           });
       }
   }

   function checkBody() {
       const modal = document.querySelector('[data-modal]:not(.hidden)');
       if(modal) {
           document.body.classList.add('has-modal');
       }  else {
           document.body.classList.remove('has-modal');
       }
   }
});
