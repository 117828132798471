import {AsyncForEach} from "@/js/plugins/AsyncForEach";

domLoaded(() => {
    const lazyLoadElements = document.getElementsByClassName('lazyload');
    for (let lazyLoadElement of lazyLoadElements) {
        lazyLoadElement.src = lazyLoadElement.getAttribute('data-src');
    }
});


function getCookie(c_name) {
    var c_value = document.cookie,
        c_start = c_value.indexOf(" " + c_name + "=");
    if (c_start == -1) c_start = c_value.indexOf(c_name + "=");
    if (c_start == -1) {
        c_value = null;
    } else {
        c_start = c_value.indexOf("=", c_start) + 1;
        var c_end = c_value.indexOf(";", c_start);
        if (c_end == -1) {
            c_end = c_value.length;
        }
        c_value = unescape(c_value.substring(c_start, c_end));
    }
    return c_value;
}

function get_language() {
    return getCookie('wp-wpml_current_language');
}

window.loadPosts = async function (postType, template, url, container, terms = null, alternate = false) {
    const urlObject = new URL(url);
    urlObject.searchParams.append('lang', get_language());
    urlObject.searchParams.append('postType', postType);
    urlObject.searchParams.append('template', template);
    if (terms) {
        urlObject.searchParams.append('terms', terms);
    }
    if (alternate) {
        urlObject.searchParams.append('alternate', 'true');
    }

    const newUrl = urlObject.toString();

    fetch(newUrl).then(response => response.json()).then(async (output) => {
        const containerElement = document.querySelector(container);

        await AsyncForEach(output.body, async (item) => {
            containerElement.insertAdjacentHTML('beforeend', item);
        }, 100);

        window.initAnimations();
        window.updateFilterItems && window.updateFilterItems();
    }).catch(error => {
        console.error(error)
    })
}
