import {onLoad} from "../onLoad";

onLoad(() => {
    const elements = document.querySelectorAll('[data-has-scrolled]');

    // return if no elements found
    if (!elements.length) {
        return;
    }

    const check = () => {
        // get scroll position
        const scrollPosition = window.scrollY;
        // iterate through all elements
        elements.forEach(element => {
            if (scrollPosition > element.offsetTop) {
                element.setAttribute('data-has-scrolled', 'true');
            } else {
                element.setAttribute('data-has-scrolled', 'false');
            }
        });
    }

    // check for scroll event
    window.addEventListener('scroll', () => {
        check();
    });

    // check for page load
    check();
});
