import {onLoad} from "../onLoad";

onLoad(() => {
    const bodyClasses = document.body.classList;

    if (!bodyClasses.contains('page-template-template-trends') && !bodyClasses.contains('page-template-template-offer')) {
        const btnElements = document.querySelectorAll('a[class*="btn"]');

        btnElements.forEach(element => {
            if (element.href.includes('afspraak') && !element.classList.contains('btn-afspraak-maken')) {
                element.classList.forEach(className => {
                    if (className.includes('btn') && className !== 'btn') {
                        element.classList.remove(className);
                    }
                });

                element.classList.add('btn-afspraak-maken');
            }
        });
    }
})

