window.addEventListener('load', function () {
    // check if aspect ratio is supported
    if (CSS.supports("(aspect-ratio: 1/1)")){
        return;
    }

    const ratios = ['16/9', '4/3', '3/2', '1/1'];

    ratios.forEach(function (ratio) {
        const [left, right] = ratio.split('/');

        const selector = `aspect-ratio-${left}/${right}`;
        const elements = document.querySelectorAll("." + CSS.escape(selector));

        elements.forEach(function (element) {
            const height = element.clientWidth * (right / left);
            element.style.height = `${height}px`;
        });
    });
});