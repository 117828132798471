import {domLoaded, onLoad} from "../onLoad";
import {tns} from "tiny-slider";

onLoad(() => {
    const contentContainer = document.querySelector('.recept-content-slides');
    const imageContainer = document.querySelector('.recept-image-slides');

    const navContainer = document.querySelector('.recept-header-nav-container');

    if(!contentContainer || !imageContainer) {
        return;
    }

    const contentSliderInstance = tns({
        container: contentContainer,
        items: 1,
        loop: false,
        rewind: true,
        autoplay: true,
        controls: false,
        nav: true,
        navContainer: navContainer,
        mouseDrag: true,
    });

    const imagesSliderInstance = tns({
        container: imageContainer,
        items: 1,
        loop: false,
        rewind: true,
        autoplay: false,
        controls: false,
        nav: false,
        mouseDrag: false,
        touch: false,
    })

    contentSliderInstance.events.on('indexChanged', (info) => {
        imagesSliderInstance.goTo(info.displayIndex - 1);
    });

    window.refreshAnimations();
});
