import {onLoad} from "../onLoad";
import printJS from 'print-js'

const getBase64FromUrl = async (url) => {
    const data = await fetch(url);
    const blob = await data.blob();
    return await new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
            const {result} = reader;

            const data = result.replace(/^data:application\/[a-z]+;base64,/, "");

            resolve(data);
        };
        reader.onerror = reject;
        reader.readAsDataURL(blob);
    });
}

onLoad(() => {
    const printButtons = document.querySelectorAll('[data-print]');

    printButtons.forEach(button => {
        const url = button.getAttribute('data-print');

        if (!url) return;


        button.addEventListener('click', async () => {
            const base64 = await getBase64FromUrl(url);
            console.log(base64);
            printJS({
                printable: base64,
                type: 'pdf',
                base64: true,
            });
        });
    });
});
