import {onLoad} from "../onLoad";
import {tns} from "tiny-slider";

onLoad(() => {
    const sliders = document.querySelectorAll('[data-slider]');

    for(let slider of sliders) {
        const sliderName = slider.getAttribute('data-slider');

        const autoplay = slider.getAttribute('data-slider-no-autoplay')===null;
        const amount = slider.getAttribute('data-slider-items');
        const twoOnSmall = !!slider.getAttribute('two-on-small');

        const prev = document.querySelector(`[data-slider-prev="${sliderName}"]`);
        const next = document.querySelector(`[data-slider-next="${sliderName}"]`);

        let settings  = {
            container: slider,
            items: twoOnSmall?2:1,
            slideBy: slider.getAttribute('data-slider-slideby') || 1,
            autoplay: autoplay,
            gutter: 13,
            rewind: true,
            controls: false,
            mouseDrag: true,
            autoplayButton: false,
            autoplayButtonOutput: false,

            responsive: {
                450: {
                    items: twoOnSmall?2:1
                },
                700: {
                    items: amount || 4
                },
            }
        }

        const tnsSliderInstance = tns(settings);

        if(prev) {
            prev.addEventListener('click', () => {
                tnsSliderInstance.goTo('prev');
            });
        }

        if(next) {
            next.addEventListener('click', () => {
                tnsSliderInstance.goTo('next');
            });
        }
    }
});
