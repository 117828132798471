import Splide from "@splidejs/splide";


window.addEventListener('load', () => {
    if(!document.querySelector('.v2-home-slider')) return;


    const splides = document.querySelectorAll('.v2-home-slider, .home-v2-uitgelichte-showroom');

    if (!splides) {
        return;
    }

    var splide = new Splide('.v2-home-slider', {
        perPage: 4,
        perMove: 1,
        gap: '32px',
        arrows: false,
        pagination: false,
        padding: "15rem",
        breakpoints: {
            768: {
                perPage: 1,
                padding: {
                    right: '10rem',
                    left: '2rem',
                },
            },
            1024: {
                perPage: 2,
                padding: {
                    right: '2rem',
                    left: '2rem',
                },
            },
            1200: {
                perPage: 3,
                padding: {
                    right: '2rem',
                    left: '2rem',
                },
            }
        }
    });

    splide.mount();

});


window.addEventListener('load', () => {
    if(!document.querySelector('.home-v2-uitgelichte-showroom')) return;
    const splide = new Splide('.home-v2-uitgelichte-showroom', {
        type: 'loop',
        perPage: 3,
        perMove: 1,
        gap: '24px',
        arrows: false,
        pagination: true,
        breakpoints: {
            768: {
                perPage: 2,
                padding: {
                    right: '6rem',
                    left: '0',
                },
            },
            1024: {
                perPage: 2,
                padding: {
                    right: '6rem',
                    left: '6rem',
                },
            },
            1200: {
                perPage: 3,
                padding: {
                    //right: '6rem',
                    // left: '6rem',
                },
            }
        }
    });

    splide.root.querySelectorAll('[data-slider-next]').forEach((el) => {
        el.addEventListener('click', () => {
            splide.go('+');
        });
    });

    splide.mount();
});
