import {onLoad} from "../onLoad";

// Local window state
window.filterState = {};

onLoad(() => {

    // All taxonomies
    const filterTypes = document.querySelectorAll('[data-filter-type-name]');
    // Get all posts that have to be filtered
    let posts = document.querySelectorAll('[data-filter-post]');

    filterTypes.forEach(filterType => {
        // taxonomy slug
        const taxonomyName = filterType.getAttribute('data-filter-type-name');
        // all terms in the taxonomies
        const checkBoxes = filterType.querySelectorAll('[data-filter-select-item]');

        // Create a key in the state for the taxonomy
        window.filterState[taxonomyName] = {};

        // Create a change event to trigger the filter later
        const changeEventName = 'change';
        const event = new Event(changeEventName);

        // populate the checkboxes
        checkBoxes.forEach(checkbox => {
            checkbox.addEventListener(changeEventName, function () {
                window.filterState[taxonomyName][checkbox.value] = checkbox.checked;
                updateFilterItems();
            }, false);
            // trigger a change event
            checkbox.dispatchEvent(event);
        });
    });
    updateFilterItems();
    initValue();

    function updateFilterItems() {
        let posts = document.querySelectorAll('[data-filter-post]');

        // Check each post
        posts.forEach(post => {
            let valid = true;

            // Check each taxonomy
            for(const [taxonomy,termIdArray] of Object.entries(formatFilterState())) {
                // dont filter if nothing is selected
                if(termIdArray.length <= 0) {
                    continue;
                }

                const taxonomyAttribute = 'data-filter-' + taxonomy;
                const postTerms = post.getAttribute(taxonomyAttribute);

                if(!postTerms) {
                    valid = false;
                    continue;
                }

                const postTermArray = postTerms.split(',');

                const isValid = termIdArray.some(id => postTermArray.indexOf(id) > -1);

                if(!isValid) {
                    valid = false;
                }
            }

            // show the post if valid and hide if not
            if(valid) {
                post.style.display = "flex";
            } else {
                post.style.display = "none";
            }
        });

        window.refreshAnimations();
    }

    window.updateFilterItems = updateFilterItems;

    // This function converts the state object into arrays for easy usage
    function formatFilterState() {
        let output =  {};
        for(const [key,value] of Object.entries(window.filterState)) {
            const ids = [];
            for(const [_key,_value] of Object.entries(value)) {
                if(_value === true) {
                    ids.push(_key);
                }
            }
            output[key] = ids;
        }

        return output;
    }

    // This function takes the select get parameter and checks checkboxes
    function initValue() {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const idsToSelect = urlParams.get('select');
        if(idsToSelect === null) {
            return;
        }

        const ids = idsToSelect.split(',');
        ids.forEach(id => {
            const checkbox = document.querySelector(`input[value="${id}"]`);
            checkbox.setChecked(true);
        })
    }
});
