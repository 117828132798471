// generate stylesheets
import "./exports";
// import theme styling
import "@styling/theme.scss";
// import "./dependencies/autoload";
import "@/js/main";

window.addEventListener('load', () => {
    import('./after-load/load').then(module => {
        module.default();
    });
});
