import {onLoad} from "../onLoad";

onLoad(() => {
   const buttons = document.querySelectorAll('.close');
   buttons.forEach(button => {
       button.addEventListener('click', function (e) {
           e.preventDefault();

           setCookie('alert_box', 'closed', '10');
           checkCookie();
       }, false);
   })

    checkCookie();

    function checkCookie() {
        if(getCookie('alert_box') === 'closed') {
            const alert_box = document.querySelector('.alert-box');
            if(alert_box) {
                alert_box.style.display = 'none';
            }
            // window.setHeaderHeight();
        }
    }

    function setCookie(name, value, days) {
        var expires = "";
        if (days) {
            var date = new Date();
            date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
            expires = "; expires=" + date.toUTCString();
        }
        document.cookie = name + "=" + (value || "") + expires + "; path=/";
    }

    function getCookie(name) {
        var nameEQ = name + "=";
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') c = c.substring(1, c.length);
            if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
        }
        return null;
    }

});
