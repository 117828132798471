function SetHeaderHeight() {
   const header = document.querySelector<HTMLElement>('header');
   if(!header) {
        return;
   }

   const headerHeight = header.offsetHeight;
   document.body.style.setProperty('--header-height', headerHeight + 'px');

   const leftOverHeight = window.innerHeight - headerHeight;
   document.body.style.setProperty('--left-over-height', leftOverHeight + 'px');
}

window.addEventListener('load', function () {
    SetHeaderHeight();
});
