import {onLoad} from "../onLoad";

onLoad(() => {
    const formToggles = document.querySelectorAll('[show-form]');

    formToggles.forEach((toggle) => {
        toggle.addEventListener('click', function() {
            formToggles.forEach(toggle => toggle.classList.remove('active'));
            toggle.classList.add('active');

            switchForms(toggle.getAttribute('show-form'));
        }, false);
    });


    function switchForms(formId) {
        const forms = document.querySelectorAll('[form-filter-id]');
        forms.forEach(form => {
            const currentId = form.getAttribute('form-filter-id');
            if(formId === currentId) {
                form.classList.remove('hidden');
            } else {
                form.classList.add('hidden');
            }
        })
    }
});
