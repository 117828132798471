const referrers = (window as any).referrers ?? [];
const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
const char = urlParams.get('ch');
const hiddenReferrerField = document.querySelector('.referrer-field');
const hiddenReferrerInput = hiddenReferrerField?.querySelector('input');

referrers.forEach(referrer => {
    if (isReferrerSameDomain(referrer)) {
        makeCookie('referrer', encodeURIComponent(referrer), false)

        if (hiddenReferrerInput) {
            hiddenReferrerInput.setAttribute('value', decodeURL(referrer));
        }
    }
})

window.addEventListener('DOMContentLoaded', () => {
    if (char) {
        makeCookie('character', char, true)
    }
})

window.addEventListener('load', () => {
    const encodedReferrer = getCookie('referrer');
    const decodedReferrer = encodedReferrer ? decodeURL(encodedReferrer) : '';

    const value = hiddenReferrerInput && decodedReferrer ? decodedReferrer : 'geen';
    hiddenReferrerInput?.setAttribute('value', value);
});

// functions:

function isReferrerSameDomain(urlString: string | URL) {
    try {
        const url = new URL(urlString);
        const referrer = new URL(document.referrer);
        return url.hostname === referrer.hostname;
    } catch (e) {
        return false;
    }
}

function decodeURL(url: string) {
    return decodeURIComponent(url.replace(/\+/g, ' '));
}

function getCookie(name: string): string | undefined {
    const value = `; ` + document.cookie;
    const parts = value.split(`; ${name}=`);
    if (parts.length > 1) {
        const cookieValue = parts.pop()?.split(';').shift();
        if (cookieValue) {
            return cookieValue.trim();
        }
    }
}

function makeCookie(name: string, value: string, date: boolean) {
    let expires = '';

    if (date) {
        const d = new Date();
        d.setTime(d.getTime() + (30 * 24 * 60 * 60 * 1000));
        expires = " expires=" + d.toUTCString() + ';';
    }

    document.cookie = name + "=" + value + ';' + expires;
}