import $ from 'jquery';

class MobileMenuListItem {
    private static expandIcon = ['far', 'fa-chevron-down'];
    private static collapseIcon = ['far', 'fa-chevron-up'];

    private el: HTMLElement;
    private link: HTMLElement;
    private submenu: HTMLElement;
    private readonly linkIcon: HTMLElement;

    public constructor(el: HTMLElement) {
        this.el = el;
        this.link = this.el.querySelector('a');
        this.submenu = this.el.querySelector('.sub-menu');

        this.linkIcon = document.createElement('i');
        this.addClasses(this.linkIcon, MobileMenuListItem.expandIcon);
        this.link.appendChild(this.linkIcon);

        this.link.addEventListener('click', (event) => {
            event.preventDefault();
            this.toggle();
        });

        const subMenuIsCurrent = this.submenu.querySelector('[aria-current="page"]') !== null;
        if (subMenuIsCurrent) {
            this.open();
        } else {
            this.close();
        }
    }

    private addClasses(element: HTMLElement, classes: string[]) {
        for (const className of classes) {
            element.classList.add(className);
        }
    }

    private removeClasses(element: HTMLElement, classes: string[]) {
        for (const className of classes) {
            element.classList.remove(className);
        }
    }

    private open() {
        this.submenu.classList.remove('sub-menu--hidden');
        this.removeClasses(this.linkIcon, MobileMenuListItem.expandIcon);
        this.addClasses(this.linkIcon, MobileMenuListItem.collapseIcon);
    }

    private close() {
        this.submenu.classList.add('sub-menu--hidden');
        this.removeClasses(this.linkIcon, MobileMenuListItem.collapseIcon);
        this.addClasses(this.linkIcon, MobileMenuListItem.expandIcon);
    }

    private isOpen() {
        return !this.submenu.classList.contains('sub-menu--hidden');
    }

    private toggle() {
        if (this.isOpen()) {
            this.close();
        } else {
            this.open();
        }
    }
}

export class MobileMenuController {
    private static instance: MobileMenuController;
    private menuLinks: JQuery;
    private overLay: JQuery;

    private constructor() {
        this.menuLinks = $('[data-open-menu]');
        this.overLay = $('[data-menu-overlay]');

        this.buildMenu();

        this.menuLinks.on('click', (e) => {
            e.preventDefault();
            console.log(this.menuLinks, e);
            this.toggle();
        });
    }

    public isOpen() {
        return $('body').hasClass('mobile-menu__open');
    }

    public open() {
        $('body').addClass('mobile-menu__open');
    }

    public close() {
        $('body').removeClass('mobile-menu__open');
    }

    public static get() {
        if (!MobileMenuController.instance) {
            MobileMenuController.instance = new MobileMenuController();
        }

        return MobileMenuController.instance;
    }

    public toggle() {
        if (this.isOpen()) {
            this.close();
        } else {
            this.open();
        }
    }

    private buildMenu() {
        const menuItemsWithSubMenus = this.overLay.find('.menu-item-has-children');
        menuItemsWithSubMenus.each((index, el) => {
            new MobileMenuListItem(el);
        });
    }
}

$(function () {
    if (!window.mobile_menu_initialized) {
        MobileMenuController.get();
        window.mobile_menu_initialized = true;
    }
})

window.addEventListener('load', function () {
    const el = document.querySelector('.mobile-dropdown');

    if (!el) {
        return;
    }

    el.setAttribute('data-dropdown-open', 'false')

    const trigger = el.querySelector('.mobile-dropdown__trigger');

    trigger.addEventListener('click', function () {
        const isOpen = el.getAttribute('data-dropdown-open') === 'true';
        el.setAttribute('data-dropdown-open', String(!isOpen));
    });

});
