import {onLoad} from "../onLoad";

onLoad(() => {
    const popup = document.querySelector('.magazine-popup');

    if (!popup) return;

    const closeButton = popup.querySelector('.should-close');
    const openButton = popup.querySelector('.should-open');

    if (!closeButton || !openButton) return;

    function vh(percent) {
        var h = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
        return (percent * h) / 100;
    }

    window.addEventListener('scroll', () => {
        if ((window.scrollY > vh(200)) && !popup.classList.contains('triggered') && !hasPopupCookie()) {
            // add open class to popup
            popup.classList.add('open');
            // add triggered class to popup so it doesn't open again when scrolling past 200vh
            popup.classList.add('triggered');
        }
    });

    closeButton.addEventListener('click', () => {
        // remove open class from popup
        popup.classList.remove('open');
        setPopupCookie()
    });

    openButton.addEventListener('click', () => {
        // add open class to popup
        popup.classList.add('open');
        removePopupCookie();
    });

    function removePopupCookie() {
        document.cookie = 'magazine-popup=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
    }

    function setPopupCookie() {
        // set cookie with expiration date of 1 week
        const expire = new Date();
        expire.setDate(expire.getDate() + 7);
        document.cookie = `magazine-popup=true; expires=${expire.toUTCString()}; path=/`;
    }

    function hasPopupCookie() {
        // check if cookie is set
        return document.cookie.indexOf('magazine-popup=true') !== -1;
    }

});
