import './plugins';
import './aspect-ratio';
import './sliders';
import './postcode';

if(!window.test) {
    console.log('test');
    window.test =true;
window.addEventListener('load', () => {
    // after-load
    import(/* webpackChunkName: "after-load" */ './after-load/index').then((module) => {
        module.default();
    });
});


}
