import './after-load/form-validator';
import {UnvalidateField, ValidateField} from "./after-load/form-validator";

// @ts-ignore
const $j = jQuery.noConflict(); //@ts-ignore
const regex = /^(\D+)([^,]+),\s*(\d+\w{2})(.*)$/;

const mode: "popup" | "inline" = "inline" // This can always be edited to "popup" or "inline"
const popup = `
    <div style="display: none; flex-direction: column; position: absolute; width: 100%; top:100%; left:0; background: none" id="selectLocationPopup"></div>
`

interface SearchResponse {
    response: {
        numFound: number;
        start: number;
        maxScore: number;
        numFoundExact: boolean;
        docs: {
            type: "postcode" | "adres";
            weergavenaam: string;
            id: string;
            score: number;
            adresType?: string;
        }[];
    };
    highlighting: {
        [key: string]: {
            suggest: string[];
        };
    };
    spellcheck: {
        suggestions: [];
        collations: [];
    };
}

interface AddressData {
    Address: string;
    HouseNumber: string;
    PostalCode: string;
    City: string;
}

const searchData = async (search: string): Promise<SearchResponse> => {
    const url = `https://api.pdok.nl/bzk/locatieserver/search/v3_1/suggest?q=${search}&rows=10&fq=*:*`;
    const req = await fetch(url, {
        method: "GET",
        headers: {
            "Accept": "application/json, text/plain, */*",
            "Accept-Language": "en-GB,en-US;q=0.9,en;q=0.8",
            "Connection": "keep-alive",
            "DNT": "1",
        },
    })

    return req.json();
}
const parseDisplayName = (displayName: string): AddressData => {
    let data = displayName.match(regex)

    if (!data) {
        console.error("Could not parse data: " + displayName);
        return null;
    }
    const address = data[1];
    const houseNumber = data[2];
    const postalCode = data[3];
    const city = data[4];

    return {
        Address: address,
        HouseNumber: houseNumber,
        PostalCode: postalCode,
        City: city,
    }
}
$j(document).bind("gform_post_render", function (event, form_id) {
    const fields = document.querySelectorAll(`#gform_${form_id} .postalcode_check`);
    const popup_instance = $j(popup);
    let latestSearch: SearchResponse;
    let IsPopupVisible = false;

    let idMap = {
        address: null as HTMLInputElement,
        postal_code: null as HTMLInputElement,
        house_number: null as HTMLInputElement,
        city: null as HTMLInputElement,
    }

    const classes = ["address", "postal_code", "house_number", "city"];

    fields.forEach((field) => {
        classes.forEach((className) => {
            if (field.classList.contains(className)) {
                idMap[className] = field as HTMLInputElement;
            }
        });
    });


    if (!idMap.address || !idMap.postal_code || !idMap.house_number || !idMap.city) return;

    $j(idMap.postal_code).append(popup_instance).css("position", "relative");

    const showPopup = () => {
        const popupInstance = $j("#selectLocationPopup");
        popupInstance.empty();

        if (!latestSearch) {
            console.log("No search data");
            return;
        }

        latestSearch.response.docs.forEach((doc) => {
            if (doc.type !== "adres") return;

            const button = document.createElement("button");
            button.innerHTML = doc.weergavenaam;
            button.type = "button";

            button.addEventListener("click", () => {
                let data = parseDisplayName(doc.weergavenaam);

                try {
                    idMap.address.querySelector("input").value = data.Address;
                    idMap.house_number.querySelector("input").value = data.HouseNumber.toString();
                    idMap.postal_code.querySelector("input").value = data.PostalCode;
                    idMap.city.querySelector("input").value = data.City;
                } catch (e) {
                    console.error(e);
                }

                popupInstance.slideUp();
            })

            popupInstance.append(button);
        });

        if (IsPopupVisible) return;
        popup_instance.slideDown();
        popupInstance.css("display", "flex");
        IsPopupVisible = true;
    }

    const hidePopup = () => {
        if (!IsPopupVisible) return;
        popup_instance.slideUp();
        IsPopupVisible = false;
    }

    const inlineFill = () => {
        const addressBlock = document.querySelector<HTMLElement>(`#gform_${form_id} .address-display`);

        if ((latestSearch.response.docs.length === 0) || (!idMap.postal_code.querySelector("input").value.trim() || !idMap.house_number.querySelector("input").value.trim())) {
            UnvalidateField(idMap.house_number.querySelector("input"));
            UnvalidateField(idMap.postal_code.querySelector("input"));
            UnvalidateField(addressBlock);
            console.log('test');
        }

        let data = parseDisplayName(latestSearch.response.docs[0].weergavenaam);

        try {
            idMap.address.querySelector("input").value = data.Address;
            idMap.house_number.querySelector("input").value = data.HouseNumber.toString();
            idMap.postal_code.querySelector("input").value = data.PostalCode;
            idMap.city.querySelector("input").value = data.City;

            ValidateField(idMap.house_number.querySelector("input"));
            ValidateField(idMap.postal_code.querySelector("input"));
            ValidateField(addressBlock);
            if (document.body.classList.contains('logged-in')) {
                addressBlock.querySelector('.street').innerHTML = data.Address;
                addressBlock.querySelector('.number').innerHTML = data.HouseNumber.toString();
                addressBlock.querySelector('.city').innerHTML = data.City;

                (data.Address) ? addressBlock.classList.add('active') : addressBlock.classList.remove('active');
            }
        } catch (e) {
        }
    }


    const searchEvent = () => {
        const postalCode = idMap.postal_code.querySelector("input").value.trim();
        const houseNumber = idMap.house_number.querySelector("input").value.trim();
        searchData(postalCode + " " + houseNumber).then((data) => {
            latestSearch = data;

            if (mode === "popup") showPopup();
        });
    }


    idMap.postal_code.addEventListener("keyup", () => {
        searchEvent();
    });
    idMap.house_number.addEventListener("keyup", () => {
        searchEvent();
    });

    if (mode === "popup") {
        idMap.postal_code.querySelector("input").addEventListener("focus", showPopup);
        idMap.postal_code.querySelector("input").addEventListener("blur", hidePopup);
    }

    if (mode === "inline") {
        idMap.postal_code.querySelector("input").addEventListener("blur", () => {
            inlineFill();
        });
        idMap.house_number.querySelector("input").addEventListener("blur", () => {
            inlineFill();
        });
    }
});
