const queryStringVersion = window.location.search;
const urlParamsVersion = new URLSearchParams(queryStringVersion);
const version = urlParamsVersion.get('version');

if (document.body.classList.contains('logged-in') || version == 'new') {
    jQuery(document).on('gform_post_render', function (event, form_id, current_page) {
        let fields = document.querySelectorAll<HTMLInputElement>(`#gform_wrapper_${form_id} input`);

        fields.forEach(field => {
            field.addEventListener('input', () => {
                setInputValue(field, 'input');
            })

            field.addEventListener('focusout', () => {
                setInputValue(field, 'focusout');
            })
        })
    });

    let fields = document.querySelectorAll<HTMLInputElement>('.gform_wrapper input');

    fields.forEach(field => {
        field.addEventListener('input', () => {
            setInputValue(field, 'input');
        })

        field.addEventListener('focusout', () => {
            setInputValue(field, 'focusout');
        })
    })
}

//SET VALUE TO FIELD
function setInputValue(field: HTMLInputElement, type: string) {
    const gfieldParent = field.closest(".gfield");

    //CREATE VALIDATION MESSAGE FIELD

    const validationMessage = document.createElement('div');
    validationMessage.classList.add('gfield_description', 'validation_message', 'gfield_validation_message');

    //DEFAULT INPUT

    if (field.type == 'text' && !gfieldParent.classList.contains('gfield_postcode') && !gfieldParent.classList.contains('gfield_huisnummer') && field.value) {
        ValidateField(field);

        //EMAIL INPUT

    } else if (field.type == 'email' && field.value && type == 'focusout') {
        if (ValidateEmail(field.value)) {
            ValidateField(field);
            gfieldParent.querySelector('.validation_message').remove();
        } else {
            UnvalidateField(field);
            validationMessage.innerHTML = "Dit e-mailadres lijkt niet te kloppen, controleer alsjeblieft het formaat (email@domein.nl).";

            if (!gfieldParent.querySelector('.validation_message')) {
                gfieldParent.appendChild(validationMessage);
            }
        }

        //PHONE INPUT

    } else if (field.type == 'tel' && field.value && type == 'focusout') {
        if (ValidatePhone(field.value)) {
            ValidateField(field);
            gfieldParent.querySelector('.validation_message').remove();
        } else {
            UnvalidateField(field);
            validationMessage.innerHTML = "Dit telefoonnumer lijkt niet te kloppen.";

            if (!gfieldParent.querySelector('.validation_message')) {
                gfieldParent.appendChild(validationMessage);
            }
        }

    } else if (gfieldParent.classList.contains('gfield_postcode')) {
        if (ValidatePostcode(field.value)) {
            //ValidateField(field);
            //gfieldParent.querySelector('.validation_message')?.remove();
        } else {
            UnvalidateField(field);
            validationMessage.innerHTML = "Deze postcode lijkt niet te kloppen, controleer alsjeblieft het formaat (1234AB).";

            if (!gfieldParent.querySelector('.validation_message')) {
                gfieldParent.appendChild(validationMessage);
            }
        }

    } else {
        UnvalidateField(field);
    }
}


//CHECK IF VALUE IS EMAIL
function ValidateEmail(mail: string) {
    return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail);
}

//CHECK IF VALUE IS PHONE
function ValidatePhone(phone: string) {
    return /^[\s()+-]*([0-9][\s()+-]*){6,20}$/.test(phone);
}

//CHECK IF VALUE IS POSTCODE
function ValidatePostcode(postcode: string) {
    return /^\d{4}[A-Z]{2}$/.test(postcode);
}

//SET FIELD TO VALIDATED
export function ValidateField(field: HTMLElement) {
    field.setAttribute('aria-invalid', 'false');
}

//SET FIELD TO UNVALIDATED
export function UnvalidateField(field: HTMLElement) {
    field.setAttribute('aria-invalid', 'true');
}