import Splide from "@splidejs/splide";

window.addEventListener('load', () => {
    const slider = document.querySelector<HTMLElement>('.referentie_quotes');
    if(!slider) return;

    const sliderInstance = new Splide(slider, {
        type: 'loop',
        perPage: 1,
        arrows: false,
        pagination: true,
        autoplay: true,
    })

    sliderInstance.mount();
});

let run = () => {

    let $ = jQuery;
    const rows = $('.custom-params tr.form-field:not(.has-content)');
    let reds=0;

    rows.each(function () {
        const input = $(this).find('.action textarea');

        if(!input.length) return;

        const val = input.val().slice(1,-1);

        const find_with_title = `div[title="/node/${val}"]`;

        const found = $(find_with_title);

        if (found.length) {
            const content = found.find('.xml-content').text();
            if(content.trim().length > 0) {
                $(this).addClass('has-content');
                $(this).css('background-color', 'green');
            } else if (!$(this).hasClass('has-content')) {
                $(this).addClass('no-content');
                $(this).css('background-color', 'red');
                reds++;
            }
        }
    });

    return reds;
}


(async () => {
    let $ = jQuery;
    console.log('tagno found');

    const sleep = async(ms) => new Promise(resolve => setTimeout(resolve, ms));

    const max = 113;

    await sleep(1000);
    let last_val=null;
    const wait_for_change = async() => {
        const cur_val = $('[title="/node/id[1]"] .xml-content').text();

        if(cur_val !== last_val) {
            last_val = cur_val;
            return true;
        }

        await sleep(100);

        return await wait_for_change();
    }

    for(let i = 1; i <= max; i++) {
        const el = $('.template-sidebar [name="tagno"]')
        if(!el.length) continue;
        // run();
        await sleep(20);
        // el select
        el.trigger('focus');
        await sleep(20);
        // el type val
        el.val('');
        el.val(i);
        await sleep(20);
        // el change
        el.trigger('change');
        // el blur
        el.trigger('blur');
        await sleep(20);

        // wait for change
        await wait_for_change();

        const reds = run();

        console.log('%c' + i + '/' +max, 'color: green; font-size: 20px; font-weight: bold;')
        console.log('%c' + $('[title="/node/title[1]"] .xml-content').text(), 'color: cyan; font-size: 15px; font-weight: bold;');
        console.log('%c' + reds, 'color: red; font-size: 15px; font-weight: bold;');
    }
})();
