
window.addEventListener('load', function() {
    jQuery(document.body).on('change input', '.gfield.times select', function() {
        const options = jQuery(this).find('option:selected');
        const max = options.attr('data-max');

        jQuery('.gfield.people-amount option').each(function() {
            const value = jQuery(this).val();

            if(!parseInt(value)) {
                return;
            }

            if(parseInt(value) > parseInt(max)) {
                jQuery(this).css('display', 'none');
            } else {
                jQuery(this).css('display', 'block');
            }
        });
    });
});
