import {onLoad} from "../onLoad";

onLoad(() => {
    if(!CSS.supports('aspect-ratio','16/9')) {
        const videos = document.querySelectorAll('.video');
        videos.forEach(video => {
            const width = video.offsetWidth;
            const newHeight = Math.floor(width / 16 * 9);
            video.style.height = newHeight + 'px';
        })
    }
})
