import {onLoad} from "../onLoad";

onLoad(() => {
    const triggers = document.querySelectorAll('[data-toggle-search]');
    const overlay = document.querySelector('[data-search-overlay]');

    // return if no elements found
    if (!triggers.length || !overlay) {
        return;
    }

    const search_input = overlay.querySelector('input[type="search"]');

    const toggle = () => {
        if(overlay.classList.contains('is-visible')) {
            overlay.classList.remove('is-visible');
            document.body.classList.remove('is-search-visible');
        } else {
            overlay.classList.add('is-visible');
            document.body.classList.add('is-search-visible');

            // focus on search input
            if(search_input) {
                search_input.focus();
            }
        }
    }

    triggers.forEach(trigger => {
        trigger.addEventListener('click', toggle);
    });
});
