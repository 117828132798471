import {onLoad} from "../onLoad";
import Splide from "@splidejs/splide";

onLoad(() => {
    const element = document.querySelector('.nieuwe-recepten-slider');

    if (!element) {
        return;
    }

    const splideInstance = new Splide(element, {
        perPage: 4,
        gap: '1rem',
        pagination: false,
        arrows: false,
        breakpoints: {
            666: {
                perPage: 1,
            },
            1024: {
                perPage: 2,
                arrows: true,
                padding: '0.5rem',
                width: '80vw'
            }
        }
    });

    splideInstance.mount();

    splideInstance.on('visible', function (slide) {
        //hides right arrow if last slide visible
        if (slide.index === splideInstance.length - 1 && splideInstance.Components &&
            splideInstance.Components.Arrows && splideInstance.Components.Arrows.arrows && splideInstance.Components.Arrows.arrows.next) {
            if (!splideInstance.Components.Arrows.arrows.next.disabled) {
                splideInstance.Components.Arrows.arrows.next.disabled = true;
            }
        }
        //hides left arrow if first slide visible
        if (splideInstance.index === 0 && splideInstance.Components &&
            splideInstance.Components.Arrows && splideInstance.Components.Arrows.arrows && splideInstance.Components.Arrows.arrows.prev) {
            if (!splideInstance.Components.Arrows.arrows.prev.disabled) {
                splideInstance.Components.Arrows.arrows.prev.disabled = true;
            }
        }
    });
});
