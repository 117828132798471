import AOS from "aos";

declare global {
    interface Window {
        initAnimations: any;
        refreshAnimations: any;
    }
}

function is_safari() {
    return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
}

window.initAnimations = function() {
    AOS.init({
        duration: 1000,
        once: true,
        disable: is_safari()
    });
}

window.refreshAnimations = function() {
    if(is_safari()) {
        return;
    }
    AOS.refresh();
}

window.addEventListener('load', () => {
    window.initAnimations();
});
